<template>
  <!-- <v-container fluid> -->
  <v-form
    id="pop-up"
    @submit.prevent="onSave"
  >
    <!-- <v-card-title> -->
    <v-app-bar
      class="white pa-0"
      elevate-on-scroll
      dense
      scroll-target="#scrolling-alert-edit"
      height="50"
      extension-height="40"
      style="z-index: 3;"
    >
      <!-- <v-app-bar-nav-icon></v-app-bar-nav-icon> -->

      <!-- <v-toolbar-title
        class="ml-4"
        :data-cy="`${dataCy}_title`"
      >
        {{ $t('alert.details.title') }}
      </v-toolbar-title> -->
      <v-tabs
        v-model="tab"
        class="px-3 ml-1"
      >
        <v-tab key="investigation">
          {{ $t('alert_case_details.tabs.investigation') }}
        </v-tab>
        <v-tab
          :disabled="auditLoading"
          key="audit"
        >
          {{ $t('alert.details.audit.title') }}
        </v-tab>
      </v-tabs>

      <v-spacer />
      <v-btn
        v-if="is_locked"
        @click.prevent="dialog_unlock_confirmation = true"
        outlined
        color="error"
        class="mr-3"
        :data-cy="`${dataCy}_unlock_btn`"
      >
        {{ $t('alert.details.buttons.unlock') }}
      </v-btn>
      <v-btn
        v-else
        @click="onSave"
        outlined
        color="primary"
        class="mr-3"
        :data-cy="`${dataCy}_save_btn`"
      >
        {{ $t('alert.details.buttons.save') }}
      </v-btn>
      <v-btn
        @click.prevent="$emit('onClose')"
        fab
        color="transparent"
        class="mr-3"
        small
        depressed
      >
        <v-icon medium>
          mdi-close
        </v-icon>
      </v-btn>
      <!-- <template v-slot:extension>
        <v-tabs
          v-model="tab"
          class="px-3"
        >
          <v-tab key="investigation">
            {{ $t('alert_case_details.tabs.investigation') }}
          </v-tab>
          <v-tab
            :disabled="auditLoading"
            key="audit"
          >
            {{ $t('alert.details.audit.title') }}
          </v-tab>
        </v-tabs>
      </template> -->
    </v-app-bar>
    <!-- </v-card-title> -->
    <v-container
      fluid
      style="max-height: calc(90vh - 90px);"
      id="scrolling-alert-edit"
      class="overflow-y-auto"
      ref="alert_details_container"
    >
      <v-tabs-items v-model="tab">
        <v-tab-item key="investigation">
          <v-card class="px-3">
            <v-card-title>{{ $t('alert.details.title') }}</v-card-title>
            <v-card-text>
              <div data-cy="hit_status">
                <v-select
                  :label="$t('alert.details.form.hit_status.label')"
                  v-model="form.status"
                  :disabled="is_locked"
                  :items="hit_status_options"
                >
                  <template
                    v-slot:selection="items"
                  >
                    <div
                      data-cy="selected_hit_status"
                    >
                      {{ items.item.text }}
                    </div>
                  </template>
                  <template
                    v-slot:item="items"
                  >
                    <div
                      :data-cy="`hit_status_${items.item.value}`"
                    >
                      {{ items.item.text }}
                    </div>
                  </template>
                </v-select>
              </div>
              <div
                data-cy="exposition_menu"
              >
                <v-select
                  :label="$t('alert.details.form.exposition.label')"
                  v-model="form.exposition"
                  :value-comparator="(a, b) => parseInt(a) == parseInt(b)"
                  :disabled="is_locked"
                  :error="$v.form.exposition.$error"
                  :error-messages="$v.form.exposition.$error ? $t('validate.required', { field: $t('alert.details.form.exposition.label') }) : ''"
                  :items="exposition_options(form.status)"
                >
                  <template
                    v-slot:selection="items"
                  >
                    <div
                      data-cy="selected_exposition"
                    >
                      {{ items.item.text }}
                    </div>
                  </template>
                  <template
                    v-slot:item="items"
                  >
                    <div
                      :data-cy="`exposition_${items.item.value}`"
                    >
                      {{ items.item.text }}
                    </div>
                  </template>
                </v-select>
              </div>
              <br>
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    :disabled="is_locked"
                    :data-cy="`${dataCy}_suggestions_btn`"
                  >
                    {{ $t('alert.details.buttons.suggestions') }}
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(remarks, key) in suggested_remarks"
                    @click="form.remarks += (form.remarks.length ? '\n' : '') + remarks"
                    :key="key"
                  >
                    <v-list-item-title
                      :data-cy="`suggestion_${key}`"
                    >
                      {{ remarks }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-textarea
                :label="$t('alert.details.form.remarks.label')"
                counter
                auto-grow
                rows="1"
                row-height="15"
                v-model="form.remarks"
                :disabled="is_locked"
                :error="$v.form.remarks.$error"
                :error-messages="$v.form.remarks.$error ? $t('validate.required', { field: $t('alert.details.form.remarks.label') }) : ''"
                :data-cy="`${dataCy}_remark`"
              />
            </v-card-text>
          </v-card>
          <v-row>
            <EntityContent
              v-if="records"
              :entity-detail="{
                scanID: records.screening_encrypt_id,
                entityID: records.entity_id
              }"
            />
          </v-row>
        </v-tab-item>
        <v-tab-item key="audit">
          <v-card
            :loading="auditLoading"
            min-height="60vh"
          >
            <v-card-title v-if="!changes.size">
              {{ $t('alert.details.audit.no_records') }}
            </v-card-title>
            <v-card-text v-else>
              <v-row>
                <v-col cols="2">
                  <vue-slider
                    :disabled="changes.size <= 1"
                    v-model="audit.index"
                    direction="ttb"
                    :tooltip="'always'"
                    :tooltip-placement="'right'"
                    :data="Array.from(changes.values())"
                    :data-value="'id'"
                    style="display: inline-block; margin: 30px 0;"
                    :style="{ height: `${(35 * changes.size)}px` }"
                  >
                    <template v-slot:tooltip="{ value, focus }">
                      <div
                        :class="['tooltip-inner', { focus }]"
                        style="min-width: 120px;"
                      >
                        <DateFormat
                          :date="trails[value].create_date"
                          :showtime="true"
                        />
                      </div>
                    </template>
                  </vue-slider>
                  <br>
                  <v-btn
                    text
                    color="primary"
                    v-if="is_more"
                    :loading="auditLoading"
                    @click.prevent="moreAudits"
                  >
                    More ({{ total - (page + 1) * limit }}+)
                  </v-btn>
                </v-col>
                <v-col cols="10">
                  <v-row>
                    <v-col>
                      <v-card-subtitle class="pl-0">
                        {{ $t('alert.details.audit.previous') }}
                      </v-card-subtitle>
                    </v-col>
                    <v-col cols="1">
    &nbsp;
                    </v-col>
                    <v-col>
                      <v-card-subtitle class="pl-0">
                        {{ $t('alert.details.audit.new') }}
                      </v-card-subtitle>
                    </v-col>
                  </v-row>
                  <div class="font-weight-light">
                    {{ $t('alert.details.form.hit_status.label') }}
                  </div>
                  <v-card-text class="grey lighten-3 rounded">
                    <v-row>
                      <v-col>
                        <span
                          v-for="(hit_status, key) in changes.get(audit.index).hit_status.filter(x => !x.added)"
                          :key="key"
                          :style="hit_status.removed ? 'color: #F44336': ''"
                        >
                          {{ hit_status.value }}
                        </span>
                      </v-col>
                      <v-col
                        class="align-self-center text-center"
                        cols="1"
                      >
                        <v-icon>mdi-arrow-right</v-icon>
                      </v-col>
                      <v-col>
                        <span
                          v-for="(hit_status, key) in changes.get(audit.index).hit_status.filter(x => !x.removed)"
                          :key="key"
                          :style="hit_status.added ? 'color: #4CAF50': ''"
                        >
                          {{ hit_status.value }}
                        </span>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <br>
                  <div class="font-weight-light">
                    {{ $t('alert.details.form.exposition.label') }}
                  </div>
                  <v-card-text class="grey lighten-3 rounded">
                    <v-row>
                      <v-col>
                        <span
                          v-for="(exposition, key) in changes.get(audit.index).exposition.filter(x => !x.added)"
                          :key="key"
                          :style="exposition.removed ? 'color: #F44336': ''"
                        >
                          {{ exposition.value }}
                        </span>
                      </v-col>
                      <v-col
                        class="align-self-center text-center"
                        cols="1"
                      >
                        <v-icon>mdi-arrow-right</v-icon>
                      </v-col>
                      <v-col>
                        <span
                          v-for="(exposition, key) in changes.get(audit.index).exposition.filter(x => !x.removed)"
                          :key="key"
                          :style="exposition.added ? 'color: #4CAF50': ''"
                        >
                          {{ exposition.value }}
                        </span>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <br>
                  <div class="font-weight-light">
                    {{ $t('alert.details.form.remarks.label') }}
                  </div>
                  <v-card-text class="grey lighten-3 rounded">
                    <v-row>
                      <v-col>
                        <TextareaSafeDisplay
                          v-for="(remarks, key) in changes.get(audit.index).remarks.filter(x => !x.added)"
                          :key="key"
                          :style="remarks.removed ? 'color: #F44336': ''"
                          :value="remarks.value"
                          nl2br="⏎<br>"
                        />
                      </v-col>
                      <v-col
                        class="align-self-center text-center"
                        cols="1"
                      >
                        <v-icon>mdi-arrow-right</v-icon>
                      </v-col>
                      <v-col>
                        <TextareaSafeDisplay
                          v-for="(remarks, key) in changes.get(audit.index).remarks.filter(x => !x.removed)"
                          :key="key"
                          :style="remarks.added ? 'color: #4CAF50': ''"
                          :value="remarks.value"
                          nl2br="⏎<br>"
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <br>
                  <div class="font-weight-light">
                    {{ $t('alert_case_details.updatedBy') }}
                  </div>
                  <v-card-text class="grey lighten-3 rounded">
                    <v-row>
                      <v-col>
                        <span
                          v-for="(updated_by, key) in changes.get(audit.index).updated_by.filter(x => !x.added)"
                          :key="key"
                          :style="updated_by.removed ? 'color: #F44336': ''"
                        >
                          {{ updated_by.value }}
                        </span>
                      </v-col>
                      <v-col
                        class="align-self-center text-center"
                        cols="1"
                      >
                        <v-icon>mdi-arrow-right</v-icon>
                      </v-col>
                      <v-col>
                        <span
                          v-for="(updated_by, key) in changes.get(audit.index).updated_by.filter(x => !x.removed)"
                          :key="key"
                          :style="updated_by.added ? 'color: #4CAF50': ''"
                        >
                          {{ updated_by.value }}
                        </span>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
      <v-dialog
        v-model="dialog_unlock_confirmation"
        persistent
        max-width="350"
      >
        <!-- <template v-slot:activator="{ on, attrs }">
						<v-btn color="primary" dark v-bind="attrs" v-on="on">
							Open Dialog
						</v-btn>
					</template> -->
        <v-card>
          <v-card-title>{{ $t('dialogs.alert_unlock.title') }}</v-card-title>
          <v-card-text>{{ $t('dialogs.alert_unlock.text') }}</v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="error darken-1"
              text
              @click="is_locked = false; dialog_unlock_confirmation = false;"
            >
              {{ $t('dialogs.alert_unlock.buttons.yes') }}
            </v-btn>
            <v-btn
              color="darken-1"
              text
              @click="dialog_unlock_confirmation = false"
            >
              {{ $t('dialogs.alert_unlock.buttons.no') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </v-form>
  <!-- </v-container> -->
</template>
<script type="text/javascript">
import * as helper from '@/store/helper'
import Vue from 'vue'
import Vuelidate from 'vuelidate'
import { requiredIf, maxLength } from 'vuelidate/lib/validators'
import { mapState, mapGetters, mapActions } from 'vuex'
import VueSlider from 'vue-slider-component'
import DateFormat from '@/components/common/DateFormat'
import TextareaSafeDisplay from '@/components/common/TextareaSafeDisplay'
import 'vue-slider-component/theme/antd.css'
import EntityContent from '@/components/popups/PopupScreeningDetail/EntityContent'

Vue.use(Vuelidate)

export default {
  components: {
    VueSlider,
    DateFormat,
    TextareaSafeDisplay,
    EntityContent
  },
  props: {
    records: {
      type: Object,
      default: null
    },
    dataCy: {
      type: String,
      default: ''
    }
  },
  watch: {
    records (records) {
      if (records) {
        Object.assign(this.form, records)
        this.is_locked = records.status !== 0
        this.findAudits(records)
        this.$refs.alert_details_container.scrollTop = 0
      }
    },
    form: {
      deep: true,
      handler () {
        this.$nextTick(() => {
          for (var i = 0; i < this.exposition_options(this.form.status).length; i++) {
            if (String(this.form.exposition) === String(this.exposition_options(this.form.status)[i].value)) {
              return
            }
          }
          // CLEAR
          this.form.exposition = ''
        })
      }
    }
  },
  computed: {
    ...mapGetters('alerts', [
      'hit_status_options',
      'suggested_remarks',
      'exposition_options'
    ]),
    ...mapState('alert_case', {
      auditLoading: state => state.audit.requestLoading,
      changes: state => state.audit.changes,
      trails: state => state.audit.trails,
      is_more: state => state.audit.is_more,
      total: state => state.audit.total,
      limit: state => state.audit.limit,
      page: state => state.audit.page
      // options: state => state.listing.options
    })
  },
  data () {
    return {
      is_locked: false,
      requestLoading: false,
      form: {
        exposition: '',
        remarks: '',
        status: ''
      },
      audit: {
        index: 1
      },
      dialog_unlock_confirmation: false,
      tab: ''
    }
  },
  validations () {
    return {
      form: {
        exposition: {
          required: requiredIf(() => this.form.status === 1 || this.form.status === 2)
        },
        remarks: {
          required: requiredIf(() => this.form.status === 1 || this.form.status === 2),
          maxLength: maxLength(255)
        }
      }
    }
  },
  methods: {
    ...helper,
    ...mapActions('alert_case', [
      'findAudits',
      'moreAudits'
    ]),
    onSave () {
      this.$v.form.$touch()

      if (this.$v.form.$invalid) {
        return this.$notify({
          type: 'error',
          title: this.$t('alert.details.msg.valid.title'),
          text: this.$t('alert.details.msg.valid.invalid')
        })
      }
      // else {
      // 	this.$notify({
      // 		type: 'success',
      // 		title: 'Validate Alert Detail',
      // 		text: 'Alert detail is valid'
      // 	});
      // }

      // TODO: SAVE
      this.requestLoading = true
      // this.$emit('onSave', this.form);
      this.$store.dispatch('alert_case/update', this.form).then((results) => {
        this.requestLoading = false
        if (results.status === 200) {
          Object.assign(this.form, results.data)
          // this.prepareForm();
          this.$notify({
            type: 'success',
            title: this.$t('alert.details.msg.save.title'),
            text: this.$t('alert.details.msg.save.success')
          })
          this.$emit('onSave', this.form)
          // this.$refs.table.getData()
          // vm.updateAlertInfo();
        } else {
          this.$notify({
            type: 'error',
            title: this.$t('alert.details.msg.save.title'),
            text: this.$t('alert.details.msg.save.error')
          })
        }
        // this.dialog_alert_detail = false;
        // this.dialog_alert_detail_record = null;
      }, (unusedError) => {
        // console.log(error);
        // this.requestLoading = false;
        // this.$notify({
        // 	type: 'error',
        // 	title: 'Save Alert',
        // 	text: 'Save Failed (Exception)'
        // })
        // this.dialog_alert_detail = false;
        // this.dialog_alert_detail_record = null;
      })
    }
  },
  mounted () {
    if (this.records) {
      Object.assign(this.form, this.records)
      this.is_locked = this.records.status !== 0
      this.findAudits(this.records)
      // this.$store.dispatch('alerts/findAudits', this.records)
    }
  }
}
</script>
<style type="text/css" scoped>
/*.v--modal {
	max-height: 90vh;
    top: 5vh !important;
    overflow: auto !important;
}
.v--modal-overlay {
	z-index: 9999;
}*/
#pop-up {
    /*padding: 5px 30px;*/
    /* height: 92vh; */
    overflow: auto;
}
.content {
	max-height: calc(92vh - 49px);
    overflow: auto;
    box-sizing: border-box;
}
textarea {resize: vertical;}
.remark_dropdown ul {box-shadow: 0 0 3px 0px;outline: none;white-space: normal;max-width: 100%;z-index: 10001;}
.remark_dropdown ul>li>a {white-space: normal;word-break: break-all;}
</style>
