<template>
  <div class="wrapper">
    <v-data-table
      :headers="headers"
      :items="items"
      :options.sync="options"
      :server-items-length="totalItems"
      :loading="loading"
      class="elevation-1 col-12"
    >
      <template v-slot:[`item.entity_type`]="{ item }">
        <v-icon
          v-if="item.entity_type == 'INDIVIDUAL'"
          color="blue lighten-3"
        >
          mdi-account
        </v-icon>
        <v-icon
          v-else
          color="blue lighten-3"
        >
          mdi-bank
        </v-icon>
      </template>
      <template v-slot:[`item.entity_name`]="{ item }">
        <div>
          <span class="font-weight-bold">{{ item.entity_name }}</span>
        </div>
        <small class="caption grey--text">
          {{ $t('ewe_screen.entities_table.column.entityId.label') }}: {{ item.entity_id }}
        </small>
      </template>
      <template v-slot:[`item.entity_picture`]="{ item }">
        <v-menu
          :nudge-width="200"
          bottom
          right
          offset-x
          open-on-hover
          :disabled="!item.entity_picture"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-img
              v-bind="attrs"
              v-on="on"
              slot="activator"
              max-width="45"
              class="my-2"
              :src="item.entity_picture || defaultPortraitIcon"
              aspect-ratio="1"
              :contain="item.entity_picture ? false : true"
              :style="item.entity_picture ? 'cursor: zoom-in' : ''"
            >
              <template v-slot:placeholder>
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-progress-circular
                    indeterminate
                    color="grey"
                  />
                </v-row>
              </template>
            </v-img>
          </template>
          <v-card>
            <v-img
              contain
              max-width="245"
              :src="item.entity_picture || defaultPortraitIcon"
            />
          </v-card>
        </v-menu>
      </template>
      <template v-slot:[`item.entity_categories`]="{ item }">
        <ScreeningCategoryChips :category="item.entity_categories" />
      </template>
      <template v-slot:[`item.entity_dob`]="{ item }">
        {{ `${(item.entity_dob && item.entity_dob.length >= 4 ? item.entity_dob : null) || 'NA'}` }}
      </template>
      <template v-slot:[`item.entity_countries`]="{ item }">
        <span
          v-for="(country, index) in (item.entity_countries || []).slice(0, 3)"
          :key="index"
          class="mr-1"
        >
          <flag
            :iso="country.iso2"
            :title="($i18n.locale.toLowerCase() === 'zh-hant' ? country.displayNameZhHant : country.displayName)"
            :squared="false"
            style="font-size:20px"
          />
        </span>
        <span
          v-if="(item.entity_countries || []).length > 3"
          class="grey--text text-caption"
        >
          <br>
          + {{ (item.entity_country || []).length - 3 }} countr(ies)
        </span>
        <span
          v-if="(item.entity_countries || []).length === 0"
        >
          N/A
        </span>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <v-chip
          small
          label
          class="text-uppercase font-weight-bold"
          :class="`status_${item.status}`"
        >
          {{
            item.status === 0
              ? $t('alert_case_details.table.headers.status.options.pending')
              : (item.status === 1
                ? $t('alert_case_details.table.headers.status.options.true')
                : $t('alert_case_details.table.headers.status.options.false'))
          }}
        </v-chip>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-btn
          icon
          @click="() => {
            dialog_detail_record = item
            dialog_detail = true
          }"
          data-cy="btn_viewAlertCaseEntity"
        >
          <v-icon small>
            mdi-pencil
          </v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <v-dialog
      v-model="dialog_detail"
      max-width="90%"
      persistent
    >
      <template v-slot:default="dialog">
        <v-card>
          <PopupAlertCaseEntity
            :records="dialog_detail_record"
            @onSave="(form) => {
              getDataFromApi()
              dialog_detail = false
              dialog_detail_record = null
            }"
            @onClose="() => {
              dialog.value = false
              dialog_detail = false
              dialog_detail_record = null
            }"
            data-cy="alert_detail"
          />
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import ScreeningCategoryChips from '@/components/common/ScreeningCategoryChips'
import DefaultPortraitIcon from '@/assets/default_portrait_picture.svg'
import PopupAlertCaseEntity from '@/components/popups/PopupAlertCaseEntity'

export default {
  components: {
    ScreeningCategoryChips,
    PopupAlertCaseEntity
  },
  props: {
    caseId: {
      type: [Number, String],
      default: null
    }
  },
  computed: {
    headers () {
      return [
        {
          text: this.$t('alert_case_details.table.headers.entity_type.label'),
          align: 'start',
          sortable: false,
          value: 'entity_type'
        },
        { text: this.$t('alert_case_details.table.headers.entity_name.label'), value: 'entity_name' },
        { text: this.$t('alert_case_details.table.headers.entity_picture.label'), sortable: false, value: 'entity_picture' },
        { text: this.$t('alert_case_details.table.headers.entity_categories.label'), value: 'entity_categories' },
        { text: this.$t('alert_case_details.table.headers.entity_dob.label'), value: 'entity_dob' },
        { text: this.$t('alert_case_details.table.headers.entity_countries.label'), value: 'entity_countries' },
        { text: this.$t('alert_case_details.table.headers.status.label'), value: 'status' },
        { text: this.$t('listing.table.action.label'), sortable: false, value: 'action', width: '80px' }
      ]
    },
    defaultPortraitIcon () {
      return DefaultPortraitIcon
    }
  },
  data () {
    return {
      totalItems: 0,
      items: [],
      loading: true,
      options: {},
      dialog_detail: false,
      dialog_detail_record: null
    }
  },
  watch: {
    caseId () {
      this.getDataFromApi()
    },
    options: {
      handler () {
        this.getDataFromApi()
      },
      deep: true
    }
  },
  methods: {
    ...mapActions({
      getAlertCaseEntities: 'alert_case/findWhereEntities',
      countAlertCaseEntities: 'alert_case/countEnities'
    }),
    getDataFromApi () {
      this.loading = true
      Promise.all([
        this.getAlertCaseEntities({
          parent: this.caseId,
          criteria: {
            limit: this.options.itemsPerPage,
            skip: (parseInt(this.options.page) - 1) * this.options.itemsPerPage,
            sort: this.options.sortBy.map((sortBy, key) => {
              return `${sortBy} ${this.options.sortDesc[key] ? 'desc' : 'asc'}`
            })
          }
        }),
        this.countAlertCaseEntities({
          parent: this.caseId,
          criteria: {}
        })
      ])
        .then(([result, resultCnt]) => {
          this.items = result.data
          this.totalItems = resultCnt.data.count
          this.loading = false
        })
    }
  },
  mounted () {
    this.$store.commit('scan/CLEAR_CACHED_ENTITY_DETAILS')
  }
}
</script>

<style scoped>
.wrapper {
  width: 100%;
}

.status_0 {
  background-color: #f8dfc3 !important;
  color: #e29350 !important;
}

.status_1 {
  background-color: #f6cfcb !important;
  color: #dd6d6b !important;
}

.status_2 {
  background-color: #cdfde1 !important;
  color: #55b189 !important;
}

</style>
