var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[_c('v-data-table',{staticClass:"elevation-1 col-12",attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.totalItems,"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.entity_type",fn:function(ref){
var item = ref.item;
return [(item.entity_type == 'INDIVIDUAL')?_c('v-icon',{attrs:{"color":"blue lighten-3"}},[_vm._v(" mdi-account ")]):_c('v-icon',{attrs:{"color":"blue lighten-3"}},[_vm._v(" mdi-bank ")])]}},{key:"item.entity_name",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.entity_name))])]),_c('small',{staticClass:"caption grey--text"},[_vm._v(" "+_vm._s(_vm.$t('ewe_screen.entities_table.column.entityId.label'))+": "+_vm._s(item.entity_id)+" ")])]}},{key:"item.entity_picture",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"nudge-width":200,"bottom":"","right":"","offset-x":"","open-on-hover":"","disabled":!item.entity_picture},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-img',_vm._g(_vm._b({staticClass:"my-2",style:(item.entity_picture ? 'cursor: zoom-in' : ''),attrs:{"slot":"activator","max-width":"45","src":item.entity_picture || _vm.defaultPortraitIcon,"aspect-ratio":"1","contain":item.entity_picture ? false : true},slot:"activator",scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey"}})],1)]},proxy:true}],null,true)},'v-img',attrs,false),on))]}}],null,true)},[_c('v-card',[_c('v-img',{attrs:{"contain":"","max-width":"245","src":item.entity_picture || _vm.defaultPortraitIcon}})],1)],1)]}},{key:"item.entity_categories",fn:function(ref){
var item = ref.item;
return [_c('ScreeningCategoryChips',{attrs:{"category":item.entity_categories}})]}},{key:"item.entity_dob",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(("" + ((item.entity_dob && item.entity_dob.length >= 4 ? item.entity_dob : null) || 'NA')))+" ")]}},{key:"item.entity_countries",fn:function(ref){
var item = ref.item;
return [_vm._l(((item.entity_countries || []).slice(0, 3)),function(country,index){return _c('span',{key:index,staticClass:"mr-1"},[_c('flag',{staticStyle:{"font-size":"20px"},attrs:{"iso":country.iso2,"title":(_vm.$i18n.locale.toLowerCase() === 'zh-hant' ? country.displayNameZhHant : country.displayName),"squared":false}})],1)}),((item.entity_countries || []).length > 3)?_c('span',{staticClass:"grey--text text-caption"},[_c('br'),_vm._v(" + "+_vm._s((item.entity_country || []).length - 3)+" countr(ies) ")]):_vm._e(),((item.entity_countries || []).length === 0)?_c('span',[_vm._v(" N/A ")]):_vm._e()]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"text-uppercase font-weight-bold",class:("status_" + (item.status)),attrs:{"small":"","label":""}},[_vm._v(" "+_vm._s(item.status === 0 ? _vm.$t('alert_case_details.table.headers.status.options.pending') : (item.status === 1 ? _vm.$t('alert_case_details.table.headers.status.options.true') : _vm.$t('alert_case_details.table.headers.status.options.false')))+" ")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","data-cy":"btn_viewAlertCaseEntity"},on:{"click":function () {
          _vm.dialog_detail_record = item
          _vm.dialog_detail = true
        }}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1)]}}],null,true)}),_c('v-dialog',{attrs:{"max-width":"90%","persistent":""},scopedSlots:_vm._u([{key:"default",fn:function(dialog){return [_c('v-card',[_c('PopupAlertCaseEntity',{attrs:{"records":_vm.dialog_detail_record,"data-cy":"alert_detail"},on:{"onSave":function (form) {
            _vm.getDataFromApi()
            _vm.dialog_detail = false
            _vm.dialog_detail_record = null
          },"onClose":function () {
            dialog.value = false
            _vm.dialog_detail = false
            _vm.dialog_detail_record = null
          }}})],1)]}}]),model:{value:(_vm.dialog_detail),callback:function ($$v) {_vm.dialog_detail=$$v},expression:"dialog_detail"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }